import React, { useState } from 'react';
import {GiHamburgerMenu} from 'react-icons/gi'
import {MdOutlineRestaurantMenu} from 'react-icons/md'

import images from '../../constants/images'
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)


  return(
  <nav className="app__navbar">
    <div className="app__navbar-logo">
      <img src={images.gericht} alt="app__logo" />
    </div>
    <ul className="app__navbar-links">
      <li className='p__opensans'><a href='#home'>Accueil</a></li>
      <li className='p__opensans'><a href='#about'>À propos</a></li>
      <li className='p__opensans'><a href='#menu'>La Carte</a></li>
      <li className='p__opensans'><a href='#awards'>Récompenses</a></li>
      <li className='p__opensans'><a href='#contact'> Contact</a></li>
    </ul>
    <div className='app__navbar-login'>
      <a href='#login' className='p__opensans'>Se connecter</a>
      <div />
      <a href='/' className='p__opensans'>Réservez une Table</a>
    </div>

    <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li className='p__opensans'><a href='#home' onClick={()=>setToggleMenu(false)}>Accueil</a></li>
              <li className='p__opensans'><a href='#about' onClick={()=>setToggleMenu(false)}>À propos</a></li>
              <li className='p__opensans'><a href='#menu' onClick={()=>setToggleMenu(false)}>La Carte</a></li>
              <li className='p__opensans'><a href='#awards' onClick={()=>setToggleMenu(false)}>Récompenses</a></li>
              <li className='p__opensans'><a href='#contact' onClick={()=>setToggleMenu(false)}> Contact</a></li>
            </ul> 
        </div>
      )}

    </div>    
  </nav>
  )
};

export default Navbar;